import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import PageRouter from "./Router/PageRouter";
import { useEffect, useState } from "react";
import axios from "axios";

// import PageRoutes from "./routes";

function App() {
  const [first, setfirst] = useState(true)
  const [checkdata, setcheckdata] = useState({})

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://frontapi.devhubtech.com/api/checkApp?platform=web&packagename=ludobrother',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);

        setfirst(response.data?.data?.paid)
        setcheckdata(response.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {

    }
  }, [])
  return (
    <div>
      {first ?
        <Router>
          <PageRouter />
        </Router>
        : <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p style={{ color: "red" }}>{checkdata?.deactivemessage}</p>
        </div>
      }
    </div>

  );
}

export default App;
