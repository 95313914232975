import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import DatePicker from "react-datepicker";

import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import { Col, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import SvgIcon from "../const/SvgIcon";
import Box3 from "./Box3";

function Setting() {
  const [show, setShow] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showOtpBoxsata, setShowOtpBoxdata] = useState("");
  const dispatch = useDispatch();
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );

  const [userBuss, setuserBuss] = useState({});

  console.log(userBuss);

  useEffect(() => {
    setStartDate(new Date(get_setting?.withdrawalstarttime));
    setStartDate2(new Date(get_setting?.withdrawalendtime));
    setuserBuss(get_setting);
  }, [get_setting]);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  useEffect(() => {
    // dispatch(adminAction.getInvoceDetails({}))
    dispatch(allapiAction.getsetting({}));

    return () => {};
  }, []);

  const submitProduct = () => {
    try {
      const data = new FormData();

      if (!userBuss?.referAmount) {
        toast.error("please enter referAmount");
      } else if (!userBuss?.gamecommision) {
        toast.error("please enter game commision");
      } else if (!userBuss?.withdrwalcommission) {
        toast.error("please enter withdrwal commission");
      } else if (!userBuss?.maxmumwithdrwal) {
        toast.error("please enter maxmum withdrwal");
      } else if (!userBuss?.minimumwithdrwal) {
        toast.error("please enter minimum withdrwal");
      } else if (!userBuss?.minimumrecharge) {
        toast.error("please enter minimum recharge");
      } else if (!userBuss?.maxmumrecharge) {
        toast.error("please enter maxmum recharge");
      } else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        // data.append('gstNo', userBuss?.gstNo)
        // data.append('companyName', userBuss?.companyName)
        // data.append('email', userBuss?.email)
        // data.append('address', userBuss?.address)
        // data.append('fb', userBuss?.fb)
        // data.append('insta', userBuss?.insta)
        // data.append('twitter', userBuss?.twitter)
        // data.append('youtube', userBuss?.youtube)
        // data.append('number', userBuss?.number)
        // data.append('pinterst', userBuss?.pinterst)

        // data.append('invoiceLogo', images[0]?.file)

        userBuss.withdrawalstarttime = startDate;
        userBuss.withdrawalendtime = startDate2;

        // console.log(startDate);
        // console.log(startDate2);

        dispatch(allapiAction.createSetting(userBuss));
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false);
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  //   const submitChangeVerify2 = () => {
  //     if (!showOtpBoxsata) {
  //       toast.error('please enter otp')
  //     } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
  //       toast.error('otp invalid')
  //     } else {
  //       dispatch(
  //         allapiAction.verifyWhatsapp({
  //           whatsappNumber: userBuss?.whatsappNumber,
  //           otp: showOtpBoxsata,
  //         })
  //       )
  //       setShowOtpBox(false)
  //       setShow(false)
  //     }
  //   }

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Setting"}
            // subHeading={`Total ${get_transaction_list?.totalDocs || 0} Transaction`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Update">
            <span className="jop_btn" onClick={handleShow}>
              Update
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">refer amount</label>
                <input
                  name="referAmount"
                  className="form-control"
                  placeholder="Enter refer Amount"
                  type="number"
                  value={userBuss?.referAmount}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">refer Commision</label>
                <input
                  name="referUpCommision"
                  className="form-control"
                  placeholder="Enter refer Commision"
                  type="number"
                  value={userBuss?.referUpCommision}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">game commision</label>
                <input
                  name="gamecommision"
                  className="form-control"
                  placeholder="Enter game commision"
                  type="number"
                  value={userBuss?.gamecommision}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrwal commission</label>
                <input
                  name="withdrwalcommission"
                  className="form-control"
                  placeholder="Enter withdrwal commission"
                  type="number"
                  value={userBuss?.withdrwalcommission}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">minimum withdrawal</label>
                <input
                  name="minimumwithdrwal"
                  className="form-control"
                  placeholder="Enter  minimum withdrawal"
                  type="number"
                  value={userBuss?.minimumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum withdrawal</label>
                <input
                  name="maxmumwithdrwal"
                  className="form-control"
                  placeholder="Enter  maxmum withdrawal"
                  type="number"
                  value={userBuss?.maxmumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Minimum recharge</label>
                <input
                  name="minimumrecharge"
                  className="form-control"
                  placeholder="Enter minimum recharge"
                  type="number"
                  value={userBuss?.minimumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum recharge</label>
                <input
                  name="maxmumrecharge"
                  className="form-control"
                  placeholder="Enter maxmum recharge"
                  type="number"
                  value={userBuss?.maxmumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vpa</label>
                <input
                  name="vpa"
                  className="form-control"
                  placeholder="Enter vpa"
                  type="text"
                  value={userBuss?.vpa}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">name</label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={userBuss?.name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">merchant Code</label>
                <input
                  name="merchantCode"
                  className="form-control"
                  placeholder="Enter  merchant Code"
                  type="text"
                  value={userBuss?.merchantCode}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrawal start time</label>
                {/* <input
                  name='withdrawalstarttime'
                  className='form-control'
                  placeholder='Enter withdrawalstarttime'
                  type='text'
                  onChange={(e) => {
                    hendleChangerole(e)
                  }}
                /> */}
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrawal end time</label>
                {/* <input
                  name='withdrawalendtime'
                  className='form-control'
                  placeholder='Enter withdrawalendtime'
                  type='text'
                  onChange={(e) => {
                    hendleChangerole(e)
                  }}
                /> */}
                <DatePicker
                  className="form-control"
                  selected={startDate2}
                  onChange={(date) => setStartDate2(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
              {/* <div className='mb-3'>
                  <label className='form-label'>youtube</label>
                  <input
                    name='youtube'
                    className='form-control'
                    placeholder='Enter youtube'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>number</label>
                  <input
                    name='number'
                    className='form-control'
                    placeholder='Enter number'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div> */}
            </div>
            {/* ) : ( */}
            {/* <div className='card-body'>
                

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div> */}
            {/* )} */}
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
<div className="mt-30">

      <Box3 />
</div>

    </div>
  );
}

export default Setting;
