import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  BATTEL_LIST_ADMIN,
  WINNING_REQ,
  WITHDRAWAL_LIST,
  KYS_LIST,
  GET_TRANSACTION_LISTS,
  USER_DETAILS,
  BATTEL_DETAILS,
  GET_SETTING,
  DESHBOARD_STATE,
  GET_MESSAGE_LISTS,
} from "../common/constant";

const initialState = {
  loading: false,

  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: {},
  quiz_list: {},
  question_list: {},
  deshboard: {},
  battels_list_admin: {},
  winning_req: {},
  withdrwarl_list: {},
  kyc_list: {},
  get_transaction_list: {},
  user_details: {},
  battels_details: {},
  get_setting: {},
  get_message_list: {},

  faqs_list: [],
  deshboard_state: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case BATTEL_LIST_ADMIN:
      return { ...state, battels_list_admin: action.payload };
    case WINNING_REQ:
      return { ...state, winning_req: action.payload };
    case WITHDRAWAL_LIST:
      return { ...state, withdrwarl_list: action.payload };
    case KYS_LIST:
      return { ...state, kyc_list: action.payload };
    case GET_TRANSACTION_LISTS:
      return { ...state, get_transaction_list: action.payload };
    case USER_DETAILS:
      return { ...state, user_details: action.payload };
    case BATTEL_DETAILS:
      return { ...state, battels_details: action.payload };
    case GET_SETTING:
      return { ...state, get_setting: action.payload };
    case DESHBOARD_STATE:
      return { ...state, deshboard_state: action.payload };
    case GET_MESSAGE_LISTS:
      return { ...state, get_message_list: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
