import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import TestTable from "../Component/TestTable/TestTable";
import Bettel from "../Component/Bettel/Bettel";
import BettelReq from "../Component/Bettel/BettelReq";
import WithdrawalRequest from "../Component/WithdrawalRequest/WithdrawalRequest";
import Kyc from "../Component/Kyc/Kyc";
import Transaction from "../Component/Transaction/Transaction";
import UserDetails from "../Component/User/UserDetails";
import Bettalview from "../Component/Bettel/Bettalview";
import Setting from "../Component/Setting/Setting";
import BettalviewWin from "../Component/Bettel/BettalviewWin";
import Message from "../Component/Message/Message";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/user-details/:id" element={<UserDetails />} />
          <Route path="/battle-view/:id" element={<Bettalview />} />
          <Route path="/battle-view-win/:id" element={<BettalviewWin />} />
          <Route path="/users/contact/:id" element={<Contact />} />
          <Route path="/battle" element={<Bettel />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/message" element={<Message />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/kyc-pending" element={<Kyc />} />
          <Route path="/winner-request" element={<BettelReq />} />
          <Route path="/withdrawal-request" element={<WithdrawalRequest />} />
          {/* <Route path="/faq-category" element={<FaqCategory />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
